import { useContext, useEffect, useRef, useState } from "react";
import { getLetterOrNumberFromCode, keyCodes } from "../utils/KeyCodes";
import styles from "./InlineCategory.module.scss";
import {
  OverlayScrollbarsComponent,
  OverlayScrollbarsComponentRef,
} from "overlayscrollbars-react";

import "overlayscrollbars/overlayscrollbars.css";
import { useClickOutsideElement, useKeyDown } from "../utils/customHooks";
import { InlineTransactionContext } from "./InlineTransaction";
import { FirestoreDocCategory } from "breadcommon";
import { firestoreUpdateTransaction } from "../firebaseio/firestoreIo";
import { UserContext } from "../firebaseio/UserContext";
import { CategoriesContext } from "../firebaseio/CategoriesContext";

function ExpandedInlineCategory(props: {
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  transactionId: string;
  createNewRule: (categoryIdAction: string) => void;
}): JSX.Element {
  const [highlightIndex, setHighlightIndex] = useState<number | null>(null);
  const [inputValue, setInputValue] = useState<string>("");
  const user = useContext(UserContext);
  const categories = useContext(CategoriesContext);
  const expandedContainerRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const menuRef = useRef<OverlayScrollbarsComponentRef>(null);
  const itemRefs = useRef<(HTMLButtonElement | null)[]>([]);
  useClickOutsideElement(
    expandedContainerRef,
    () => props.setIsExpanded(false),
    true,
    document
  );

  const filteredCategories: FirestoreDocCategory[] =
    createFilteredCategoriesList();
  if (filteredCategories.length === 0 && highlightIndex != null) {
    setHighlightIndex(null);
  }

  useEffect(focusOnCreation, []);

  function focusOnCreation() {
    inputRef.current?.focus();
  }

  function showNoneOption() {
    return inputValue === "";
  }

  function createFilteredCategoriesList(): FirestoreDocCategory[] {
    if (showNoneOption()) {
      return [
        {
          id: "",
          name: "none",
          emoji: "",
          created_timestamp_secs: 0,
          order_position: 0,
        },
        ...Array.from(categories.values()),
      ];
    }
    return Array.from(categories.values()).filter(
      (category: FirestoreDocCategory) =>
        category.name.toLowerCase().includes(inputValue.toLowerCase())
    );
  }

  function handleInputChanged(value: string) {
    setInputValue(value);
    setHighlightIndex(0);
  }

  function scrollItemIntoView(itemIndex: number) {
    itemRefs.current[itemIndex]?.scrollIntoView({
      block: "nearest",
      inline: "nearest",
      behavior: "smooth",
    });
  }

  function updateTransactionCategory(category: FirestoreDocCategory) {
    let categoryId: string | null = null;
    if (category.id) {
      categoryId = category.id;
    }
    firestoreUpdateTransaction(
      user.uid,
      props.transactionId,
      {
        manual_data: {
          category_id: categoryId,
        },
      },
      null
    );
  }

  function markReviewed(): void {
    firestoreUpdateTransaction(
      user.uid,
      props.transactionId,
      {
        manual_data: { reviewed: true },
      },
      null
    );
  }

  function choose(category: FirestoreDocCategory): void {
    updateTransactionCategory(category);
    props.setIsExpanded(false);
  }

  function handleKeyDown(
    event: React.KeyboardEvent<HTMLDivElement>,
    keyCode: string
  ): void {
    const lastCategoryIndex = filteredCategories.length - 1;
    switch (keyCode) {
      case keyCodes.UPARROW:
        event.preventDefault(); // don't want to scroll the transaction row too
        event.stopPropagation();
        if (filteredCategories.length === 0) {
          break;
        } else if (highlightIndex === null || highlightIndex === 0) {
          setHighlightIndex(lastCategoryIndex);
          scrollItemIntoView(lastCategoryIndex);
        } else {
          setHighlightIndex(highlightIndex - 1);
          scrollItemIntoView(highlightIndex - 1);
        }
        break;
      case keyCodes.DOWNARROW:
        event.preventDefault(); // don't want to scroll the transaction row too
        event.stopPropagation();
        if (filteredCategories.length === 0) {
          break;
        } else if (
          highlightIndex === null ||
          highlightIndex === lastCategoryIndex
        ) {
          setHighlightIndex(0);
          scrollItemIntoView(0);
        } else {
          setHighlightIndex(highlightIndex + 1);
          scrollItemIntoView(highlightIndex + 1);
        }
        break;
      case keyCodes.ENTER:
        if (event.repeat) return;
        if (highlightIndex !== null) {
          choose(filteredCategories[highlightIndex]);
          markReviewed();
          if (event.ctrlKey || event.metaKey) {
            props.createNewRule(filteredCategories[highlightIndex].id);
          }
        } else {
          event.stopPropagation();
        }
        break;
      case keyCodes.ESC:
        event.stopPropagation();
        if (event.repeat) return;
        props.setIsExpanded(false);
        break;
      case keyCodes.TAB:
        event.stopPropagation();
        if (event.repeat) return;
        props.setIsExpanded(false);
        break;
      default:
        break;
    }
  }

  function createMenuItem(category: FirestoreDocCategory, i: number) {
    let classes = styles.expandedItem;
    let displayText =
      category.emoji !== undefined && category.emoji !== ""
        ? ` ${category.emoji}   ${category.name}`
        : `        ${category.name}`;
    if (i === highlightIndex) {
      classes = `${classes} ${styles.expandedItemHighlight}`;
    }
    if (i === 0 && showNoneOption()) {
      classes = `${classes} ${styles.expandedNoneItem}`;
      displayText = "  --    none";
    }
    return (
      <button
        key={`category${i}`}
        ref={(ref) => (itemRefs.current[i] = ref)}
        className={classes}
        onClick={(e) => {
          choose(filteredCategories[i]);
          markReviewed();
        }}
        tabIndex={-1}
      >
        {displayText}
      </button>
    );
  }

  var menu = filteredCategories
    .sort((a, b) => a.order_position - b.order_position)
    .map(createMenuItem);

  return (
    <div
      ref={expandedContainerRef}
      className={styles.expandedContainer}
      onKeyDown={(e) => handleKeyDown(e, e.key)}
    >
      <input
        type="text"
        ref={inputRef}
        className={
          filteredCategories.length === 0
            ? `${styles.expandedInput} ${styles.expandedRedWarning}`
            : styles.expandedInput
        }
        defaultValue={""}
        spellCheck="false"
        onChange={(e) => handleInputChanged(e.target.value)}
      />
      {/* options described at https://kingsora.github.io/OverlayScrollbars/ */}
      <OverlayScrollbarsComponent
        options={{
          scrollbars: { autoHide: "never" },
          overflow: { x: "hidden" },
        }}
        className={styles.expandedMenu}
        ref={menuRef}
      >
        {menu}
      </OverlayScrollbarsComponent>
    </div>
  );
}

function CollapsedInlineCategory(props: {
  setIsExpanded: React.Dispatch<React.SetStateAction<boolean>>;
  categoryId: string | null;
}): JSX.Element {
  const { transactionSelected, ruleForFieldHighlighting, keyListenerShouldListen } = useContext(
    InlineTransactionContext
  );
  const categories = useContext(CategoriesContext);
  const buttonRef = useRef<HTMLDivElement>(null);
  useKeyDown(buttonRef, keyListenerShouldListen, handleKeyDown);

  function handleKeyDown(event: KeyboardEvent): void {
    if (event.metaKey || event.ctrlKey) {
      // We want `cmd/ctrl + `enter` and `cmd/ctr` + `c` etc. to do other things
      // like mark reviewed or copy text.
      return;
    }
    if (event.code === keyCodes.TAB) {
      event.preventDefault();
      event.stopPropagation();
      if (event.repeat) return;
      props.setIsExpanded(true);
      return;
    }
    if (getLetterOrNumberFromCode(event.code)) {
      event.stopPropagation();
      if (event.repeat) return;
      props.setIsExpanded(true);
      return;
    }
  }

  let categoryDisplay = "";

  let category: FirestoreDocCategory | undefined = undefined;
  if (props.categoryId) {
    category = categories.get(props.categoryId);
  }
  if (category !== undefined) {
    categoryDisplay = category.name;
    if (category.emoji !== undefined) {
      categoryDisplay = `${category.emoji}   ${category.name}`;
    }
  }

  return (
    <div
      ref={buttonRef}
      className={`${styles.collapsedButton} ${transactionSelected ? styles.transactionSelected : ""
        } ${ruleForFieldHighlighting.get !== null &&
          ruleForFieldHighlighting.get.action.category_id !== null
          ? styles.ruleFieldActionHighlight
          : ""
        }`}
      onClick={(e) => {
        props.setIsExpanded(true);
      }}
    >
      <div className={styles.collapsedOverflowContainer}>{categoryDisplay}</div>
    </div>
  );
}

export function InlineCategory(props: {
  transactionId: string;
  categoryId: string | null;
  createNewRule: (categoryIdAction: string) => void;
}): JSX.Element {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  if (isExpanded) {
    return (
      <ExpandedInlineCategory
        setIsExpanded={setIsExpanded}
        transactionId={props.transactionId}
        createNewRule={props.createNewRule}
      ></ExpandedInlineCategory>
    );
  }

  return (
    <CollapsedInlineCategory
      setIsExpanded={setIsExpanded}
      categoryId={props.categoryId}
    ></CollapsedInlineCategory>
  );
}
